// `Notes.vue` is a Vue.js component that displays a list of notes as cards. Each card contains the note's title, content, and tags. Users can edit, delete, or archive notes. The component also includes a form for adding new notes. The notes are stored in a Firebase Firestore database and are bound to the component using Vuefire. The component includes methods for adding, editing, deleting, and archiving notes, as well as a filter for special tags. The component is used in the Live app for managing notes.
<template>
  <div class="ml-2 mr-2">
    <!-- Display notes as cards -->
    <b-card-group columns>
      <template v-for="note in notes">
      <b-card :key="note.id" v-if="!note.archived" :title="editable[note.id] ? '' : note.title" class="mb-2 note">
        <b-form-input v-model="note.title" v-if="editable[note.id]" @blur="$set(editable, note.id, false); editNote(note)"></b-form-input>
        <div class="card-text" v-if="!editable[note.id]">
          <span v-html="formatMarkdown(note)"/>
        </div>
        <b-form-textarea v-model="note.content" v-else @blur="$set(editable, note.id, false); editNote(note)"></b-form-textarea>
        <b-form-tags v-model="note.tags" remove-on-delete v-if="editable[note.id]" @change="editNote(note)"></b-form-tags>
        <div v-else>
          <b-badge v-for="tag in note.tags" :key="tag" variant="secondary">{{ tag }}</b-badge>
        </div>
        <div class="form-inline mt-2 mb-2 sub-mr-2">
          <b-button @click="$set(editable, note.id, true)" v-if="!editable[note.id]" variant="outline-primary">
            <font-awesome-icon icon="pencil"/>
          </b-button>
          <b-button @click="deleteNote(note.id)" variant="outline-danger">
            <font-awesome-icon icon="trash"/>
          </b-button>
          <b-button @click="archiveNote(note.id)" variant="outline-secondary">
            <font-awesome-icon icon="archive"/>
          </b-button>
        </div>
        <p v-if="note.lastEditTimestamp" style="font-size: small">Last edited: {{ note.lastEditTimestamp.toDate() || formatDate }}</p>
      </b-card>
    </template>
    </b-card-group>
    <!-- Add new note form -->
    <b-form @submit.prevent="addNote">
      <b-form-group label="Title">
        <b-form-input v-model="newNote.title" required></b-form-input>
      </b-form-group>
      <b-form-group label="Content">
        <b-form-textarea v-model="newNote.content" required></b-form-textarea>
      </b-form-group>
      <b-form-group label="Tags">
        <b-form-tags v-model="newNote.tags" remove-on-delete></b-form-tags>
      </b-form-group>
      <b-button type="submit" variant="primary">Add Note</b-button>
    </b-form>
  </div>
</template>

<script>
import { getLog } from "@/services/log"
let log = getLog("notes");
import { db, serverTimestamp } from "@/services/db"
import marked from "marked";

export default {
  data() {
    return {
      editable: {},
      newNote: {
        title: '',
        content: '',
        tags: []
      }
    }
  },
  mounted() {
    log.log("uid", this.$store.account.uid);
  },
  computed: {
    notes() { return this.$store.state.notes; },
    account() { return this.$store.state.account; },
    specialTags() {
      return ['prompt', 'system', 'command'];
    }
  },      
  methods: {
    formatMarkdown(note) {
      let res = marked.parse(note.content)
      return res;
    },
    addNote() {
      log.log("Adding new note");
      this.newNote.createdTimestamp = serverTimestamp();
      this.newNote.lastEditTimestamp = serverTimestamp();
      db.collection(`NotesItems/${this.$store.account.uid}/items`).add(this.newNote)
      .then(() => {
        log.log("New note added successfully");
        this.newNote.title= '';
        this.newNote.content = '';
        this.newNote.tags = [];
      })
      .catch((error) => {
        log.log("Error adding new note: ", error);
      });
    },
    editNote(note) {
      log.log("Editing note with id: ", note.id);
      note.lastEditTimestamp = serverTimestamp();
      db.collection(`NotesItems/${this.$store.account.uid}/items`).doc(note.id).update(note)
      .then(() => {
        log.log("Note edited successfully");
      })
      .catch((error) => {
        log.log("Error editing note: ", error);
      });
    },
    deleteNote(noteId) {
      if (confirm("Are you sure you want to delete this note?")) {
        log.log("Deleting note with id: ", noteId);
        db.collection(`NotesItems/${this.$store.account.uid}/items`).doc(noteId).delete()
        .then(() => {
          log.log("Note deleted successfully");
        })
        .catch((error) => {
          log.log("Error deleting note: ", error);
        });
      }
    },
    archiveNote(noteId) {
      log.log("Archiving note with id: ", noteId);
      db.collection(`NotesItems/${this.$store.account.uid}/items`).doc(noteId).update({archived: true})
      .then(() => {
        log.log("Note archived successfully");
      })
      .catch((error) => {
        log.log("Error archiving note: ", error);
      });
    }
  },
  filters: {
    tagFilter(tag) {
      if (this.specialTags.includes(tag)) {
        return tag.toUpperCase();
      } else {
        return tag;
      }
    }
  }
}
</script>

<style scoped>
.note {
  max-height: 75vh; 
  overflow-y: auto;
}

</style>